// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
  siteKey: '6LcJZxAaAAAAAM-EGRxVZNPz1v9RQw016J4E_4AT',
  siteSecret: '6LcJZxAaAAAAAEeHsQ8EBBv8YWUZ0FFB-IRbQ8jb',
  apiUrl: 'https://imagekiwi-sandbox.salesonepro.com/api/e',
  authUrl: 'https://imagekiwi-sandbox.salesonepro.com/signin',
  socketUrl: 'wss://imagekiwi-sandbox.salesonepro.com',
  clientId: 'dJGKOylUDyeizuz05UANSfYgRFQlHMoYvNJnBhBN',
  clientSecret: 'DwfkBrEppx21cdyHwgVmOLenVJobdEtSBWEutrM5y1146ysRCUC0B2KKTIwUY5YDniGEPhlXWCSBudjBaCDQynpwr74W1LABJ7GRihy8yjDDj3oxpYIjhrblu8JJjf1U',
  tableRowLimit: '15',
  sanmarTrackingUrl: 'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=',
  rocketShipServerUrl: 'https://printserver.local:8081',
  printSocketUrl: 'wss://printserver.salesonepro.com:8887',
  brotherProfile: 'CO12',
  brotherPrinterVersion: [3, 4],
  printSocketKey: 'BD6F38FA9C8EE9386ABEDD725A4B1F05F4E74866517BFE46A7C81A71AF42697D'
};
